/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Smooth Scrolling Script by @chriscoyier
        $('a[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
        
        // Initialize the slick slider js to all post-relation content elements
        $(document).ready(function(){
            $('.post-relation-slides').slick({
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 5000
            });
          });
          
          // Manual-generated WordPress Navi WITH First-Level Landingpage
        if (Modernizr.touch) {
            var screen = $(window).width();
            if (screen >= 768) {
                $( '#main-nav li:has(ul)' ).doubleTapToGo();
            }
            if (screen <= 767) {
                //Add Toggle Icons to Nav for Dropdown Touch Device Support
                $('.menu-item-has-children > a').after('<div class="toggle"><span class="glyphicon glyphicon-chevron-down"></span></div>');
                $('.current-menu-ancestor').addClass('opened');
                $('.current-menu-parent').addClass('opened');
                $('.menu-item-has-children.current-menu-item').addClass('opened');
            }
            // Prevents the Submenus from stayin' opened after page reloads like it does on phones
            $('.menu-item-has-children > .toggle').on('click', function () {
                var element = $(this).parent('li');
                if (element.hasClass('opened')) {
                    element.removeClass('opened');
                    element.find('li').removeClass('opened');
                    element.find('ul').slideUp();
                }
                else {
                    element.addClass('opened');
                    element.children('ul').slideDown();
                    element.siblings('li').children('ul').slideUp();
                    element.siblings('li').removeClass('opened');
                    element.siblings('li').find('li').removeClass('opened');
                    element.siblings('li').find('ul').slideUp();
                }
            });
        }
        
        // Manual call for bootstrap scrollspy script - uncomment if needed
        // $('body').scrollspy({target: '#main-nav'});
        $( ".shiftnav-toggle" ).click(function() {
            $(this).toggleClass( "active" );
        });
        
        $( ".exit-off-canvas" ).click(function() {
            $("#nav-toggle").toggleClass( "active" );
        });
        
        // Toggle function for offcanvas navigation
        $( ".shiftnav-toggle, .exit-off-canvas" ).click(function() {
            $( ".off-canvas-wrapper" ).toggleClass("move-left");
        });
        
        // Scroll to Top Button fading function
        $(document).scroll(function () {
            var y = $(this).scrollTop();
            if (y > 1000) {
                $('.scrollTop').fadeIn();
            } else {
                $('.scrollTop').fadeOut();
            }
        });
        
        // Togle Submenus on TouchScreens incl. ShiftNav Submenus
        if ($('html').hasClass('touchevents')) { 
            var viewport = $(window).width();
            if(viewport < 768){
                var mainNavparentLink = $('#nav-primary li:has(ul) > a');
                var shiftNavparentLink = $('.shiftnav-menu li:has(ul) > a');
                
                $(mainNavparentLink).append('<span class="toggle glyphicon glyphicon-plus" aria-hidden="true"></span>');
                $(shiftNavparentLink).append('<span class="toggle glyphicon glyphicon-plus" aria-hidden="true"></span>');
                
                $(mainNavparentLink).click(function(event){event.preventDefault(); $(this).parent().toggleClass('open');});
                $(shiftNavparentLink).click(function(event){event.preventDefault(); $(this).parent().toggleClass('open');}); 
            }else {
                $( '#nav-primary li:has(ul)' ).doubleTapToGo();
                $( '.shiftnav-menu li:has(ul)' ).doubleTapToGo();
            }
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
